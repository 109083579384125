<template>
    <div class="site-login w-100 p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-2 p-2">
        <h2 class="text-purple mb-3">Create An Account</h2>
        <signup-form></signup-form>
    </div>
</template>

<script>
    import SignupForm from "./SignupForm.vue";

    export default {
        name: "guest-signup",
        components: {
            SignupForm
        }
    };
</script>
