<template>
    <Form
            @submit="signupUser(data.inputFormData)"
            :validation-schema="schema"
            v-slot="{ errors }"
    >
        <div class="form-group mb-3">
            <label class="label">Name</label>
            <Field
                    type="text"
                    class="form-control"
                    v-model="data.inputFormData.name"
                    placeholder="Name"
                    name="name"
            />
            <span class="text-danger">{{ errors.name }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Username</label>
            <Field
                    type="text"
                    class="form-control"
                    v-model="data.inputFormData.username"
                    placeholder="Username"
                    name="username"
            />
            <span class="text-danger">{{ errors.username }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Email</label>
            <Field
                    type="text"
                    class="form-control"
                    v-model="data.inputFormData.email"
                    placeholder="Email"
                    name="email"
            />
            <span class="text-danger">{{ errors.email }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Mobile</label>
            <Field
                    type="number"
                    class="form-control"
                    v-model="data.inputFormData.mobile"
                    placeholder="Mobile"
                    name="mobile"
            />
            <span class="text-danger">{{ errors.mobile }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Password</label>
            <password name="password"
                      class="form-control form-control-sm"
                      v-model="data.inputFormData.password"
                      placeholder="Password"/>
            <span class="text-danger">{{ errors.password }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Confirm Password</label>
            <password name="c_password"
                      class="form-control form-control-sm"
                      v-model="data.inputFormData.c_password"
                      placeholder="Confirm Password"/>
            <span class="text-danger">{{ errors.c_password }}</span>
        </div>
        <div class="form-group mb-2">
            <button
                    type="submit"
                    class="form-control btn btn-primary rounded submit px-3"
            >
                Sign Up
            </button>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 m-auto py-3">
            <div class="border-bottom-span"></div>
        </div>
        <div class="row mb-3">
            <div
                    class="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-11 m-auto text-center"
            >
                <router-link class="site-text-btn font-weight-500 m-auto" :to="'/'">
                    I am already a member
                </router-link>
            </div>
        </div>
    </Form>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import HelperFunction from "@/common/helpers";
    import {useStore} from "vuex";
    import {useRoute, useRouter} from "vue-router";
    import {inject, onMounted} from "vue";
    import Password from "@/common/components/input/Password";

    export default {
        name: "signup-form",
        components: {
            Form,
            Field,
            Password
        },
        setup() {
            const {data, showToast} = HelperFunction();
            const store = useStore();
            const router = useRouter();
            const route = useRoute();
            const axios = inject('axios')
            const toast = inject('toast')

            onMounted(() => {
                if (route.params.param) {
                    getSignUpData()
                }
            })

            function getSignUpData() {
                axios.get(`signup/user/${route.params.param}`)
                    .then((response) => {
                        if (response.data) {
                            data.inputFormData = response.data.data
                        } else {
                            toast.warning('This invitation is no longer valid')
                            return
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }

            try {
                //VALIDATION
                const schema = Yup.object().shape({
                    name: Yup.string().required(),
                    username: Yup.string().required(),
                    email: Yup.string().required(),
                    password: Yup.string()
                        .min(8, "Password must be at least 8 characters")
                        .required("Password is required"),
                    c_password: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Confirm Password is required'),
                });

                //ACTION REQUEST
                async function signupUser(formData) {
                    try {
                        const signupEndpoint =
                            process.env.VUE_APP_BACKEND_URL + vueConfig.Auth.signUpEndPoint;

                        const response = await store.dispatch("formAction", {
                            formData: formData,
                            action: signupEndpoint,
                        });
                        showToast(response);
                        router.push(`/`);
                    } catch (err) {
                        showToast(err);
                    }
                }

                return {
                    schema,
                    signupUser,
                    data,
                };
            } catch (err) {
                console.log(err);
            }
        },
    };
</script>